// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer {
  padding: 0.5rem 0.75rem;
  border: 1px solid #E0E0E7;
  border-radius: 12px 12px 12px 3px;
  background-color: white;
  color: black;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  transition: 180ms;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
}
.answer span {
  line-height: 1.4;
  font-size: 14px;
}
@media only screen and (min-width: 1280px) {
  .answer span {
    font-size: 16px;
  }
}
.answer img {
  opacity: 0;
  transition: 250ms;
}
.answer--checked {
  background-color: rgba(0, 0, 0, 0.15);
  color: white;
}
.answer--checked img {
  opacity: 1;
}
.answer .answer__checkbox {
  display: none;
}
@media (hover: hover) {
  .answer--not-checked:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Answer/Answer.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,yBAAA;EACA,iCAAA;EACA,uBAAA;EACA,YAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,+BAAA;EACA,cCbI;ADYN;AAGE;EACE,gBAAA;EACA,eAAA;AADJ;AAGI;EAJF;IAKI,eAAA;EAAJ;AACF;AAGE;EACE,UAAA;EACA,iBAAA;AADJ;AAIE;EACE,qCAAA;EACA,YAAA;AAFJ;AAII;EACE,UAAA;AAFN;AAME;EACE,aAAA;AAJJ;AAOE;EACE;IACE,0CAAA;EALJ;AACF","sourcesContent":["@import '../../scss/variables.scss';\n\n.answer {\n  padding: 0.5rem 0.75rem;\n  border: 1px solid #E0E0E7;\n  border-radius: 12px 12px 12px 3px;\n  background-color: white;\n  color: black;\n  user-select: none;\n  cursor: pointer;\n  transition: 180ms;\n  display: grid;\n  grid-template-columns: auto 1fr;\n  grid-gap: $gap;\n\n  span {\n    line-height: 1.4;\n    font-size: 14px;\n\n    @media only screen and (min-width: $desktop) {\n      font-size: 16px;\n    }\n  }\n\n  img {\n    opacity: 0;\n    transition: 250ms;\n  }\n\n  &--checked {\n    background-color: rgba(0, 0, 0, 15%);\n    color: white;\n\n    img {\n      opacity: 1;\n    }\n  }\n\n  .answer__checkbox {\n    display: none;\n  }\n\n  @media (hover: hover) {\n    &--not-checked:hover {\n      background-color: rgba(255, 255, 255, 0.5);\n    }\n  }\n}","$gap: 1rem;\n$halfGap: 0.5rem;\n$mobile: 375px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n$answerBorderColor: #e8e8e8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
