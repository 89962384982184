import React, { useEffect, useState } from "react";
import "./Cookies.scss";
import CrossWhite from "../../assets/cross-white.png";
import Container from "../../hoc/Container/Container";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setIsCookiesShown } from "../../reducers/quizReducer";

const Cookies: React.FC = () => {
  const { isCookiesShown } = useSelector((state: RootState) => state.quiz);
  const dispatch = useDispatch();

  const location = useLocation();
  console.log("location.pathname", location.pathname);
  useEffect(() => {
    if (location.pathname !== "/") {
      dispatch(setIsCookiesShown());
    }
  }, [location]);

  if (isCookiesShown) return null;

  return (
    <Container>
      <div className="cookies">
        <div className="cookies__text">
          И хотя ёлочкам файлы cookie не нужны, а&nbsp;вот нашему сайту — очень!
          Он использует их для того, чтобы улучшить свою работу, повысить
          эффективность и&nbsp;удобс тво для пользователей. Находясь
          на&nbsp;сайте elka.budu.jobs, вы соглашаетесь на обработку
          персональных данных в&nbsp;соответствии с&nbsp;
          <a
            href="https://yo.budu.jobs/docs/privacy?_ga=2.25944254.284317960.1703173976-1697586608.1702897754"
            target="_blank"
            rel="noreferrer"
          >
            политикой конфиденциальности
          </a>
          .
        </div>
        <div className="cookies__cross">
          <img
            src={CrossWhite}
            alt=""
            onClick={() => {
              dispatch(setIsCookiesShown());
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Cookies;
