// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookies {
  position: fixed;
  bottom: 10px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 1rem auto;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  z-index: 999;
  border: 1px solid #FAFAFA;
  background-color: black;
  color: white;
}
.cookies a {
  text-decoration: underline;
}
.cookies__cross img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.cookies__text {
  flex-grow: 1;
}
@media only screen and (min-width: 1280px) {
  .cookies {
    max-width: 1280px;
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Cookies/Cookies.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,YAAA;EACA,QAAA;EACA,OAAA;EAEA,aAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;EAEA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,uBAAA;EACA,YAAA;AAJF;AAME;EACE,0BAAA;AAJJ;AAQI;EACE,WAAA;EACA,YAAA;EACA,eAAA;AANN;AAUE;EACE,YAAA;AARJ;AAWE;EApCF;IAqCI,iBClCM;IDmCN,cAAA;EARF;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.cookies {\n  position: fixed;\n  bottom: 10px;\n  right: 0;\n  left: 0;\n\n  display: flex;\n  align-items: center;\n\n  width: 100%;\n  height: auto;\n\n  margin: 1rem auto;\n  padding: 1rem 1.5rem;\n  border-radius: 10px;\n  z-index: 999;\n  border: 1px solid #FAFAFA;\n  background-color: black;\n  color: white;\n\n  a {\n    text-decoration: underline;\n  }\n\n  &__cross {\n    img {\n      width: 32px;\n      height: 32px;\n      cursor: pointer;\n    }\n  }\n\n  &__text {\n    flex-grow: 1;\n  }\n\n  @media only screen and (min-width: $desktop) {\n    max-width: $desktop;\n    margin: 0 auto;\n  }\n}","$gap: 1rem;\n$halfGap: 0.5rem;\n$mobile: 375px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n$answerBorderColor: #e8e8e8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
