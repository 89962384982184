// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgba(0, 0, 0, 0.4);
}
.contacts a {
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/components/Contacts/Contacts.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".contacts {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  color: rgba(0, 0, 0, 0.4);\n\n  a {\n    line-height: 1.4;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
