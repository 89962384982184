// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media only screen and (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1280px;
    margin: 0 40px;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
    margin: 0 40px;
  }
}
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1600px;
    margin: 0 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/hoc/Container/Container.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAIE;EAJF;IAMI,gBCLU;EDGZ;AACF;AAIE;EATF;IAUI,iBCRU;EDOZ;AACF;AAGE;EAbF;IAcI,iBCXM;IDYN,cAAA;EAAF;AACF;AAEE;EAlBF;IAmBI,iBCfW;IDgBX,cAAA;EACF;AACF;AACE;EAvBF;IAwBI,iBCnBU;IDoBV,cAAA;EAEF;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.container {\n  margin: 0 auto;\n  // padding: 0 1rem;\n\n  @media only screen and (min-width: $smallTablet) {\n    // padding: 0 5rem;\n    max-width: $smallTablet;\n  }\n\n  @media only screen and (min-width: $largeTablet) {\n    max-width: $largeTablet;\n  }\n\n  @media only screen and (min-width: $desktop) {\n    max-width: $desktop;\n    margin: 0 40px;\n  }\n\n  @media only screen and (min-width: $largeDesktop) {\n    max-width: $largeDesktop;\n    margin: 0 40px;\n  }\n\n  @media only screen and (min-width: $wideDesktop) {\n    max-width: $wideDesktop;\n    margin: 0 40px;\n  }\n}\n","$gap: 1rem;\n$halfGap: 0.5rem;\n$mobile: 375px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n$answerBorderColor: #e8e8e8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
