// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-progress-bar {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 3px;
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  height: 36px;
  padding: 0.75rem;
  background-color: white;
  border: 1px solid #E0E0E7;
  width: max-content;
}
.quiz-progress-bar__step {
  border-radius: 50%;
  background-color: #E0E0E7;
  width: 8px;
  height: 8px;
}
.quiz-progress-bar__step--current {
  width: 12px;
  height: 12px;
  border: 1px solid #B1B1BA;
}
.quiz-progress-bar__step--bad {
  background-color: #FF4F29;
}
.quiz-progress-bar__step--neutral {
  background-color: #FCE40F;
}
.quiz-progress-bar__step--good {
  background-color: #62BC4B;
}`, "",{"version":3,"sources":["webpack://./src/components/QuizProgressBar/QuizProgressBar.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,6BAAA;EACA,gCAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;AADF;AAGE;EACE,kBAAA;EACA,yBAAA;EACA,UAAA;EACA,WAAA;AADJ;AAGI;EACE,WAAA;EACA,YAAA;EACA,yBAAA;AADN;AAII;EACE,yBAAA;AAFN;AAKI;EACE,yBAAA;AAHN;AAMI;EACE,yBAAA;AAJN","sourcesContent":["@import '../../scss/variables.scss';\n\n.quiz-progress-bar {\n  border-top-left-radius: 12px;\n  border-top-right-radius: 12px;\n  border-bottom-right-radius: 12px;\n  border-bottom-left-radius: 3px;\n  display: flex;\n  align-items: center;\n  grid-gap: .25rem;\n  height: 36px;\n  padding: 0.75rem;\n  background-color: white;\n  border: 1px solid #E0E0E7;\n  width: max-content;\n\n  &__step {\n    border-radius: 50%;\n    background-color: #E0E0E7;\n    width: 8px;\n    height: 8px;\n\n    &--current {\n      width: 12px;\n      height: 12px;\n      border: 1px solid #B1B1BA;\n    }\n\n    &--bad {\n      background-color: #FF4F29;\n    }\n\n    &--neutral {\n      background-color: #FCE40F;\n    }\n\n    &--good {\n      background-color: #62BC4B;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
