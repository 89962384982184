// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-page {
  display: grid;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 1280px) {
  .quiz-page {
    min-height: 560px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.quiz-page--advice-turn {
  display: flex;
  flex-direction: column-reverse;
}
@media only screen and (min-width: 1280px) {
  .quiz-page--advice-turn {
    display: grid;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Quiz/Quiz.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,0BAAA;AADF;AAGE;EAJF;IAKI,iBAAA;IACA,qCAAA;EAAF;AACF;AAEE;EACE,aAAA;EACA,8BAAA;AAAJ;AAEI;EAJF;IAKI,aAAA;EACJ;AACF","sourcesContent":["@import '../../scss/variables.scss';\n\n.quiz-page {\n  display: grid;\n  grid-template-columns: 1fr;\n\n  @media only screen and (min-width: $desktop) {\n    min-height: 560px;\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  &--advice-turn {\n    display: flex;\n    flex-direction: column-reverse;\n\n    @media only screen and (min-width: $desktop) {\n      display: grid;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
