import React from "react";
import { YMInitializer } from "react-yandex-metrika";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Container from "../Container/Container";
import Cookies from "../../components/Cookies/Cookies";

interface ILayout {
  children: React.ReactNode;
  hideFooter?: boolean;
  isHeaderMaximized?: boolean;
  isResultPage?: boolean;
}

const Layout: React.FC<ILayout> = (props) => {
  const {
    children,
    hideFooter = false,
    isHeaderMaximized = true,
    isResultPage = false,
  } = props;

  return (
    <>
      <Cookies />
      <YMInitializer accounts={[95903245]} options={{ webvisor: true }} />
      <Container isResultPage={isResultPage}>
        <Header
          isHeaderMaximized={isHeaderMaximized}
          isResultPage={isResultPage}
        />
        <main className="main">{children}</main>
      </Container>
      {hideFooter ? null : <Footer />}
    </>
  );
};

export default Layout;
