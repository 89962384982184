import Layout from "../../hoc/Layout/Layout";
import "./Results.scss";
import Modal from "react-modal";
import HappyAle from "../../assets/results/win.svg";
import Cross from "../../assets/cross.svg";
import BurnedAle from "../../assets/results/game-over.svg";
import Qiwi from "../../assets/results/qiwi.svg";
import QiwiMobile from "../../assets/results/qiwi-mobile.svg";
import ArrowWhite from "../../assets/arrow-white.svg";
import ArrowBlack from "../../assets/arrow-black.svg";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import MediaQuery from "react-responsive";
import { useState } from "react";
import ym from "react-yandex-metrika";

const Results = () => {
  const { total } = useSelector((state: RootState) => state.quiz);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const result = total.reduce((acc, value) => acc + value);
  // const isAleBurned = result <= 75;
  const isAleBurned = false;
  Modal.setAppElement("#root");

  const handleFinishClick = () => {
    ym("reachGoal", "cta-tg");

    window.location.href = "https://t.me/+MBlkqpMM54gyNGJi";
  };

  return (
    <Layout isResultPage={true}>
      <section className="results">
        <div
          className={`results__left results__left_${
            isAleBurned ? "burned" : "happy"
          }`}
        >
          <div className="results__inner">
            <div className="results__mood">
              {isAleBurned ? (
                <img src={BurnedAle} alt="QIWI" />
              ) : (
                <img src={HappyAle} alt="QIWI" />
              )}
            </div>
            <div className="results__caption">
              {isAleBurned ? (
                <>
                  <h2>Ой, а у вас ёлка сгорела!</h2>
                  <p>
                    Не во всём тебе удалось помочь нашей зелёной героине. Самое
                    время отправить ёлочку на&nbsp;отдых и&nbsp;перезагрузку.
                    Уверены, она научится на&nbsp;собственных ошибках
                    и&nbsp;в&nbsp;следующем году будет не&nbsp;гореть,
                    а&nbsp;сверкать! А&nbsp;коллеги из&nbsp;QIWI непременно её
                    в&nbsp;этом поддержат и&nbsp;покажут, какой бывает жизнь
                    в&nbsp;оранжевом цвете 🧡 <br />
                    А&nbsp;чтобы твой Новый год прошёл ярче и&nbsp;веселее,
                    получи в&nbsp;благодарность за&nbsp;участие классные подарки
                    от&nbsp;птички QIWI!
                  </p>
                </>
              ) : (
                <>
                  <h2>Ура, ёлочка снова сверкает!</h2>
                  <p>
                    Твои советы помогли зелёной красавице поверить
                    в&nbsp;собственные силы, разобраться с&nbsp;проблемами,
                    которые возникают в&nbsp;коллективе, и&nbsp;наладить
                    правильный баланс между работой и&nbsp;отдыхом.
                  </p>
                  <br />
                  <p>
                    Команда QIWI решила поддержать ёлочку, и&nbsp;теперь 2024-й
                    она встретит в&nbsp;оранжевом цвете с&nbsp;новыми коллегами,
                    которые помогут ей расправить ветви и&nbsp;сиять ярче
                    прежнего! И&nbsp;в&nbsp;благодарность за&nbsp;такую
                    искреннюю помощь птичка QIWI принесла тебе классные подарки
                    🧡
                  </p>
                </>
              )}
            </div>
            <div
              className={`results__scroll results__scroll--${
                isAleBurned ? "burned" : "happy"
              }`}
            >
              <span>Листай ниже</span>
              {isAleBurned ? (
                <img src={ArrowWhite} alt="" />
              ) : (
                <img src={ArrowBlack} alt="" />
              )}
            </div>
          </div>
        </div>

        <div
          className={`results__right results__right_${
            isAleBurned ? "burned" : "happy"
          }`}
        >
          <div className="results__mood">
            <MediaQuery minWidth={1280}>
              <img src={Qiwi} alt="QIWI" />
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              <img src={QiwiMobile} alt="QIWI" />
            </MediaQuery>
          </div>
          <div className="results__qiwi">
            <div className="results__qiwi__bubble">
              <h2>Да ты просто подарок!</h2>
              <p>
                Спасибо за помощь в&nbsp;спасении ёлочки, мы это оценили 🧡
                <br />В QIWI принято решать все задачи по-своему, поэтому
                инициатива всегда в&nbsp;почете! Спешим поздравить тебя
                с&nbsp;Новым годом и&nbsp;пожелать тонну счастья, легкости,
                драйва и&nbsp;свободы. Надеемся на&nbsp;скорейшую встречу
                —&nbsp; в&nbsp;офисе QIWI ты всегда найдешь комьюнити
                по&nbsp;интересам и&nbsp;здоровую атмосферу, выгорать
                не&nbsp;дадим!
              </p>
            </div>
            <div className="results__qiwi__bubble">
              <p>
                Нажимай скорее на кнопку ниже, нам не терпится вручить тебе
                подарок 🎁
              </p>
            </div>
            <Button
              type="button"
              size="large"
              className={`button--${isAleBurned ? "orange" : "purple"}`}
              isResultPage
              onClick={() => {
                setIsModalOpen(true);
                ym("reachGoal", "cta-priz");
              }}
            >
              За подарками от QIWI
            </Button>
          </div>
        </div>
      </section>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        // contentLabel="Example Modal"
      >
        <div className="modal">
          <div className="modal__close">
            <img
              src={Cross}
              alt=""
              onClick={() => {
                setIsModalOpen(false);
              }}
            />
          </div>
          <div className="modal__content">
            <div className="modal__heading text--purple">
              Птичка QIWI принесла на&nbsp;хвосте классные новогодние подaрки
              —&nbsp; шанс получить их есть у&nbsp;каждого. Тебя ждут{" "}
              <span className="orange">Apple&nbsp;Watch&nbsp;SE</span>,{" "}
              <span className="orange">Яндекс&nbsp;Станция&nbsp;2</span>,
              а&nbsp;также сертификат <span className="orange">Digift.ru</span>{" "}
              на&nbsp;<span className="orange">10&nbsp;000&nbsp;рублей</span>!
            </div>
            <div className="modal__list">
              <span className="text--purple">Что нужно сделать:</span>
              <br />
              <ol>
                <li>Нажать на&nbsp;кнопку «Хочу приз»</li>
                <li>Подписаться на&nbsp;канал QIWI INSIDE в&nbsp;Телеграме</li>
                <li>
                  Нажать на кнопку «Участвовать» в&nbsp;конкурсном посте
                  в&nbsp;закрепе
                </li>
                <li>Вуаля, ты молодчина!</li>
              </ol>
            </div>
            <div className="modal__results">
              <span className="text--purple">Итоги розыгрыша — 15 января!</span>
              <br />
              <br /> Желаем много счастья и удачи в&nbsp;Новом году! Остаёмся
              на&nbsp;связи 🧡
            </div>
          </div>
          <div className="modal__actions">
            <Button
              type="button"
              size="large"
              className={`button--${isAleBurned ? "orange" : "purple"}`}
              isResultPage
              onClick={handleFinishClick}
            >
              Хочу приз
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Results;
