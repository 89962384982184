// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socials {
  display: flex;
  grid-gap: 0.5rem;
}
.socials__icon {
  background-color: rgb(177, 177, 186);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  transition: background-color 250ms;
  cursor: pointer;
}
.socials__icon:hover {
  background-color: rgb(224, 224, 231);
}
.socials__icon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Socials/Socials.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBCHQ;ADEV;AAGE;EACE,oCAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,kCAAA;EACA,eAAA;AADJ;AAGI;EACE,oCAAA;AADN;AAII;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFN","sourcesContent":["@import '../../scss/variables.scss';\n\n.socials {\n  display: flex;\n  grid-gap: $halfGap;\n\n  &__icon {\n    background-color: rgba(177, 177, 186, 1);\n    width: 2rem;\n    height: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-shrink: 0;\n    border-radius: 50%;\n    position: relative;\n    transition: background-color 250ms;\n    cursor: pointer;\n\n    &:hover {\n      background-color: rgba(224, 224, 231, 1);\n    }\n\n    img {\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n}","$gap: 1rem;\n$halfGap: 0.5rem;\n$mobile: 375px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n$answerBorderColor: #e8e8e8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
