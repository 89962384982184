// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 0 1rem;
  width: 100%;
  background-color: white;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .footer {
    padding: 0;
  }
}
.footer__container {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,WAAA;EAEA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAFF;AAIE;EAVF;IAWI,UAAA;EADF;AACF;AAGE;EACE,uBAAA;AADJ","sourcesContent":["@import '../../scss/variables.scss';\n\n.footer {\n  padding: 0 1rem;\n  width: 100%;\n\n  background-color: white;\n  height: 4rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  @media only screen and (min-width: $largeTablet) {\n    padding: 0;\n  }\n\n  &__container {\n    background-color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
