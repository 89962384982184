// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 0.5rem 1rem;
  margin: 1rem;
  border-radius: 24px;
  display: flex;
  align-items: center;
}
.header--minimized {
  justify-content: center;
}
.header--maximized {
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
}
@media only screen and (min-width: 1280px) {
  .header--maximized {
    background-color: unset;
    border-radius: unset;
    margin: unset;
    padding: 1.5rem 0rem;
  }
}
.header--overflowed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media only screen and (min-width: 1280px) {
  .header--overflowed {
    transform: translate(-50%, 0%);
    left: 50%;
    width: 1280px;
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EACE,uBAAA;AADJ;AAIE;EACE,8BAAA;EACA,0CAAA;AAFJ;AAII;EAJF;IAKI,uBAAA;IACA,oBAAA;IACA,aAAA;IACA,oBAAA;EADJ;AACF;AAIE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;AAFJ;AAII;EANF;IAOI,8BAAA;IACA,SAAA;IACA,aC7BI;ID8BJ,aAAA;EADJ;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.header {\n  padding: 0.5rem 1rem;\n  margin: 1rem;\n  border-radius: 24px;\n  display: flex;\n  align-items: center;\n\n  &--minimized {\n    justify-content: center;\n  }\n\n  &--maximized {\n    justify-content: space-between;\n    background-color: rgba(255, 255, 255, 0.5);\n\n    @media only screen and (min-width: $desktop) {\n      background-color: unset;\n      border-radius: unset;\n      margin: unset;\n      padding: 1.5rem 0rem;\n    }\n  }\n\n  &--overflowed {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n\n    @media only screen and (min-width: $desktop) {\n      transform: translate(-50%, -0%);\n      left: 50%;\n      width: $desktop;\n      padding: 20px;\n    }\n  }\n}\n","$gap: 1rem;\n$halfGap: 0.5rem;\n$mobile: 375px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n$answerBorderColor: #e8e8e8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
