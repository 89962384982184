// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing_bubble {
  background-color: #fff;
  margin-left: 12px;
  padding: 12px;
  border: 1px solid #e0e0e7;
  border-radius: 12px 12px 12px 3px;
  line-height: 19px;
}`, "",{"version":3,"sources":["webpack://./src/components/TypingBubble/TypingBubble.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,iBAAA;EACA,aAAA;EACA,yBAAA;EACA,iCAAA;EACA,iBAAA;AACF","sourcesContent":[".typing_bubble {\n  background-color: #fff;\n  margin-left: 12px;\n  padding: 12px;\n  border: 1px solid #e0e0e7;\n  border-radius: 12px 12px 12px 3px;\n  line-height: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
