// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1280px) {
  .ale {
    display: flex;
    justify-content: center;
  }
}
.ale img {
  -webkit-user-select: none;
          user-select: none;
}
.ale--answer-turn {
  display: none;
}
@media only screen and (min-width: 1280px) {
  .ale--answer-turn {
    display: flex;
  }
}
.ale--advice-turn {
  height: 50%;
  width: 100%;
}
@media only screen and (min-width: 1280px) {
  .ale--advice-turn {
    height: auto;
  }
}
.ale--advice-turn img {
  max-height: 100%;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Ale/Ale.scss"],"names":[],"mappings":"AAGE;EADF;IAEI,aAAA;IACA,uBAAA;EADF;AACF;AAGE;EACE,yBAAA;UAAA,iBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAII;EAHF;IAII,aAAA;EADJ;AACF;AAKE;EACE,WAAA;EACA,WAAA;AAHJ;AAKI;EAJF;IAKI,YAAA;EAFJ;AACF;AAII;EACE,gBAAA;EACA,eAAA;AAFN","sourcesContent":["@import '../../scss/variables.scss';\n\n.ale {\n  @media only screen and (min-width: $desktop) {\n    display: flex;\n    justify-content: center;\n  }\n\n  img {\n    user-select: none;\n  }\n\n  &--answer-turn {\n    display: none;\n\n    @media only screen and (min-width: $desktop) {\n      display: flex;\n    }\n  }\n\n\n  &--advice-turn {\n    height: 50%;\n    width: 100%;\n\n    @media only screen and (min-width: $desktop) {\n      height: auto;\n    }\n\n    img {\n      max-height: 100%;\n      max-width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
