import React from "react";
import "./Button.scss";
import Stars from "../../assets/stars.svg";

type ButtonType = "button" | "submit" | "reset";
type ButtonSize = "common" | "large";

interface IButton {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: any) => void;
  type?: ButtonType;
  size?: ButtonSize;
  className?: string;
  isResultPage?: boolean;
}

const Button: React.FC<IButton> = (props) => {
  const {
    children,
    disabled = false,
    onClick,
    type = "button",
    size = "common",
    className = "",
    isResultPage = false,
  } = props;

  const handleClick = (event: any) => {
    onClick && onClick(event);
  };

  return (
    <button
      type={type}
      className={`button button--${size} ${className}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {isResultPage ? (
        <img src={Stars} alt="" className="button__stars button__stars--left" />
      ) : null}

      {children}
      {isResultPage ? (
        <img
          src={Stars}
          alt=""
          className="button__stars button__stars--right"
        />
      ) : null}
    </button>
  );
};

export default Button;
