import React, { useEffect, useRef } from "react";
import "./Answer.scss";
import { Field, useField } from "formik";
import CheckIcon from "../../assets/check double.svg";

interface IAnswer {
  name: string;
  value: string;
  mark: number;
}

const Answer: React.FC<IAnswer> = (props) => {
  const { name, value, mark } = props;
  const [field] = useField(name);

  const valueRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (valueRef.current) {
      valueRef.current.innerHTML = value;
    }
  }, [value, valueRef]);

  return (
    <label
      className={`answer answer--${
        field.value === String(mark) ? "checked" : "not-checked"
      }`}
    >
      <Field
        type="radio"
        name={name}
        value={mark}
        className="answer__checkbox"
      />
      <span ref={valueRef}>{value}</span>
      <img src={CheckIcon} alt="check" />
    </label>
  );
};

export default Answer;
