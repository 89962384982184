import React from "react";
import "./Contacts.scss";

const Contacts = () => {
  return (
    <div className="contacts">
      <a href="mailto:hello@budu.jobs">hello@budu.jobs</a>
    </div>
  );
};

export default Contacts;
